import React, { useEffect, useMemo, useState } from "react";
import MainCover from "../components/home/MainCover";
import styles from "../styles/main.module.css";
import Spacer from "../components/common/Spacer";
import { add_contact_us } from "../api/Form/FormApi";
import Success from "../components/common/Success";
import Error from "../components/common/Error";
import background from "../assets/contact-background.png";
import Select from "react-select";
import countryList from "react-select-country-list";

const Contact = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [isError, setIsError] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    flag: 1,
    first_name: "",
    last_name: "",
    email: "",
    subject: "",
    message: "",
    country: "India",
    company_name: "",
    device: 0,
    bot_name: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await add_contact_us(contactInfo);
    if (res.message == "Contact message saved and email sent!") {
      setIsSuccess(true);
      setContactInfo({
        flag: 1,
        first_name: "",
        last_name: "",
        email: "",
        subject: "",
        message: "",
        country: "",
        company_name: "",
        device: 0,
        bot_name: "",
      });
    } else {
      setIsError(true);
    }
    console.log(res);
    console.log(contactInfo);
  };
  useEffect(()=>{
    console.log(options)
  },[])
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      data-aos="fade-down"
      className={`${styles?.["flex-column-str"]} ${styles?.[""]}  `}
    >
      {}
      {/* <MainCover /> */}
      <Spacer height={"1rem"} />
      {isSuccess ? (
        <>
          <Success text="Your information has been recorded successfully" />
        </>
      ) : isError ? (
        <Error />
      ) : (
        <div
          style={{ gap: "2rem" }}
          className={`${styles?.["w-70"]} ${styles?.["form-container"]} ${styles?.["flex-column-str"]} ${styles?.["m-auto"]}  `}
        >
          <div>
            <div className={`${styles?.["head-xlg"]} ${styles?.[""]}  `}>
              REACH OUT
            </div>
            <div className={`${styles?.["head-lg"]} ${styles?.[""]}  `}>
              TO FIND OUT HOW SPEEED.AI CAN HELP YOU
            </div>
          </div>
          <form onSubmit={handleSubmit} action="">
            <div style={{ width:"100%" }} className={`${styles?.["categories-container2"]}`} >

              <div className={`${styles?.["w-100"]}`}>
                {/* <label>Name</label> */}
                <input
                  required
                  name="first_name"
                  value={contactInfo.first_name}
                  onChange={handleInputChange}
                  placeholder="First Name"
                  className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                  type="text"
                />
              </div>
              <div className={`${styles?.["w-100"]}`}>
                {/* <div style={{ minHeight: "1.2rem" }}></div> */}

                <input
                  required
                  name="last_name"
                  value={contactInfo.last_name}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                  className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                  type="text"
                />
              </div>
            <div className={`${styles?.["w-100"]}`}>
              {/* <label>Email</label> */}
              <input
                required
                name="email"
                onChange={handleInputChange}
                value={contactInfo.email}
                placeholder="Business Email Address"
                className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                type="email"
              />
            </div>
            <div className={`${styles?.["w-100"]}`}>
              {/* <label>
                Subject{" "}
                <span style={{ fontSize: ".8rem" }}>(Max 200 characters)</span>
              </label> */}
              <input
                name="subject"
                required
                onChange={handleInputChange}
                value={contactInfo.subject}
                maxLength="200"
                placeholder="Subject"
                // placeholder="First Name"
                className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                type="text"
              />
            </div>
            <div className={`${styles?.["w-100"]}`}>
              {/* <label>
                Subject{" "}
                <span style={{ fontSize: ".8rem" }}>(Max 200 characters)</span>
              </label> */}
              <input
                name="company_name"
                required
                onChange={handleInputChange}
                value={contactInfo.company_name}
                maxLength="200"
                placeholder="Company Name"
                // placeholder="First Name"
                className={`${styles?.["inpt-box"]}  `}
                type="text"
              />
            </div>
            <select
              className={`${styles?.["inpt-box"]}  `}
              name="country"
              options={options}
              value={contactInfo.country}
              onChange={handleInputChange}
            >
              {
                options?.map((op)=>(
                  <option value={op?.label} label={op?.label}></option>
                ))
              }
            </select>

            <div>
              {/* <label>
                Message{" "}
                <span style={{ fontSize: ".8rem" }}>(Max 1000 characters)</span>{" "}
              </label> */}
              <textarea
                required
                name="message"
                onChange={handleInputChange}
                rows="3"
                style={{
                  resize: "vertical",
                  overflow: "auto",
                  height: "6rem",
                  paddingTop: ".5rem",
                }}
                maxLength="1000"
                value={contactInfo.message}
                placeholder="Anything you would like us to know?"
                className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                type="text"
              />
            </div>
            <div style={{ alignItems:"end" }} className={`${styles?.["flex-center"]}`} >
              <button
                type="submit"
                // onClick={handleSubmit}
                className={`${styles?.["orange-background"]} ${styles?.["submit-button"]}`}
              >
                <h2>Get Started</h2>
                
              </button>
            </div>
            </div>
            <Spacer height={"1rem"} />
          
          </form>
        </div>
      )}
      <Spacer height={"1rem"} />
    </div>
  );
};
export default Contact;
