import React from "react";
import styles from "../../styles/main.module.css";
import stats from "../../assets/linewhite-background.png";
        
const Card = ({data}) => {
    return (
        <div data-aos="fade-left" style={{ height:"100%" }} className={` ${styles?.[""]}  ${styles?.["cover"]} `}>
             <img className={` ${styles?.["w-100"]}  ${styles?.["cover"]} `} src={data.img} alt="" />
             <div style={{ bottom:"1%" }} className={`  ${styles?.["cover-content"]} ${styles?.["flex-column"]} `}>
                <div style={{fontSize:"1.7rem", fontWeight:"600" }} className={` ${styles?.["orange-background"]}  ${styles?.["head-sm"]} `}> {data.tag}</div>
             </div>
        </div>
    );
};
export default Card;