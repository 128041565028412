import React from "react";
import styles from "../styles/main.module.css";
import MainCover from "../components/IntellegencePlatform/MainCover";
import FlexContainer2 from "../components/common/FlexContainer2";
import more from "../assets/click on Intelligence platform/find formula for success.jpg";
import CustomCarousel from "../components/common/Slider";
import Tabs from "../components/IntellegencePlatform/Tabs";
import Spacer from "../components/common/Spacer";
import cover from "../assets/click on Intelligence platform/click on sports.jpg";

import VideoSlider from "../components/common/VideoSlider";
import ContentBanner from "../components/sports/ContentBanner";
import Categories from "../components/sports/Categories";
import LineHeading from "../components/sports/LineHeading";
import Comment from "../components/sports/Comment";
import startup from "../assets/click on Intelligence platform/google.jpg";
import google2 from "../assets/click on Intelligence platform/google2.jpg";
import vision from "../assets/click on Intelligence platform/computer-vision.png";
import { useNavigate } from "react-router-dom";


const Sports = () => {
  const navigate=useNavigate()
  const data = [
    {
      category: "Performance Medicine",
      // title: "PLAY SMARTER",
      link:"/intell/performance/med",
      summary:
        "Avoid being vulnerable to outdated EMR systems. Manage risk and compliance while protecting data to enable reliable, consistent decision-making.",
      features: [
        {
          title: "Medical History",
          details:
            "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
        },
        {
          title: "Wellness & RPE Collection",
          details:
            "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
        },
        {
          title: "Injury Lifecycle Management",
          details:
            "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
        },
      ],
    },
    {
      category: "Performance Optimization",
      // title: "OPTIMIZE YOUR HUMAN ASSETS",
      link:"/intell/performance/opt",

      summary:
        "Integrate data from the game, gym, physical testing, and other sources with medical information to get a comprehensive picture of each player's condition, progress, and objectives.",
      features: [
        {
          title: "Metric Management & Analysis",
          details:
            "Assess data strategy and process, identify improvement areas and implement systems to reach desired outcomes.",
        },
        {
          title: "Player Profiling & Benchmarking",
          details:
            "Assess data strategy and process, identify improvement areas and implement systems to reach desired outcomes.",
        },
        {
          title: "Session Planning",
          details:
            "Assess data strategy and process, identify improvement areas and implement systems to reach desired outcomes.",
        },
      ],
    },
    {
      category: "Coaching & Development",
      // title: "DATA THAT DRIVES RESULTS",
      link:"/intell/performance/c&d",

      summary:
        "By monitoring each athlete's growth, performance, and involvement, you can equip your coaches with the resources they need to organize their talent pipeline and promote player and team success.",
      features: [
        {
          title: "Individual Development & Planning",
          details:
            "Assess data strategy and process, identify improvement areas and implement systems to reach desired outcomes.",
        },
        {
          title: "Growth & Maturation",
          details:
            "Assess data strategy and process, identify improvement areas and implement systems to reach desired outcomes.",
        },
        {
          title: "Participation Management",
          details:
            "Assess data strategy and process, identify improvement areas and implement systems to reach desired outcomes.",
        },
      ],
    },
  ];
  const data2 = [
    {
      category: "League Operations",
      link:"",

      // title: "PLAY SMARTER",
      summary:
        "Simplify league-wide procedures and monitor team, player, and staff activity from daily tasks to season-long broad-based planning.",
      features: [
        {
          title: "Player Registration",
          details:
            "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
        },
        {
          title: "Staff & Official Registration",
          details:
            "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
        },
        {
          title: "Fixture Management",
          details:
            "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
        },
      ],
    },
    {
      category: "Custom Analytics",
      // title: "OPTIMIZE YOUR HUMAN ASSETS",
      link:"",

      summary:
        "Integrate data from the game, gym, physical testing, and other sources with medical information to get a comprehensive picture of each player's condition, progress, and objectives.",
      features: [
        {
          title: "Data Health",
          details:
            "To achieve the intended results, evaluate the data strategy and process, pinpoint areas for improvement, and put systems in place.",
        },
        {
          title: "Injury Review",
          details:
            "Assess data strategy and process, identify improvement areas and implement systems to reach desired outcomes.",
        },
        {
          title: "Success Landscape",
          details:
            "Learn about the tactics used by successful teams and the adjustments you can make to succeed based on data.",
        },
      ],
    },
  ];
  const content = {
    head1:
      "Integrate your people, data, and processes with the IP: Intelligence platform.",
    head2: "PERFORMANCE DATA",
    head3: "SYSTEMIZE YOUR TALENT DEVELOPMENT PIPELINE",
    head4: "STREMLINE AND CENTRALIZE OPERATIONS & LOGISTICS",
  };
  const MainCoverData = {
    title1: "MORE THAN A SPORTS EMR, BEYOND ATHLETE MONITORING SOFTWARE",
    title2: "SPORTS ",
    title3: "",
    summary:
      "Regardless of the field, pitch, or court you play on, you may succeed by planning, preparing, and performing. All of your athletes' human performance can be unlocked, and your team and league can achieve important performance measurements and results.",
    cover: cover,
  };
  const videos = [
    {
      img:startup,
      video:
        "https://x.com/i/status/1816851062147703188",
      summary:
        "All eyes on #ParisOlympics2024—but, back in India,@iitmadras has its sights set on #Olympics 2036. At #IITMSportsTechConclave, the institute said it will incubate 300 sports tech #startups to enable Indian athletes close the gap with global frontrunners.@akhil_edit reports",
      link:"https://x.com/CNBCTV18News/status/1816851062147703188?t=xknZdPkiVdVvnlz9yoH5WA&s=08"
      
    },
    {
      img:google2,
      video:
        "https://videos.pexels.com/video-files/6338366/6338366-uhd_2560_1440_25fps.mp4",
      summary:
        "Digital Darwin Partners with Google to Enhance AI Innovation in Sports and Healthcare",
      link:"https://cxotoday.com/interviews/digital-darwin-revolutionizing-sports-tech-with-computer-vision-generative-ai-and-integrated-analytics/#google_vignette"

    },
    {
      img:vision,
      video:
        "https://videos.pexels.com/video-files/5972853/5972853-uhd_2560_1440_30fps.mp4",
      summary:
        "Digital Darwin: Revolutionizing Sports Tech with Computer Vision, Generative AI, and Integrated Analytics",
      link:"https://ians.in/vmpl/digital-darwin-partners-google-enhance-ai-innovation-sports-healthcare"

    },
  ];
  return (
    <div
      style={{ gap: "4rem", alignItems: "start" }}
      className={`${styles?.["flex-column"]}`}
    >
      <MainCover data={MainCoverData} />
      <ContentBanner data={content} />
      <Spacer height={"3rem"} />

      <div data-aos="fade-right"className={`${styles?.["line-heading-cont"]} ${styles?.["w-100"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          SOLUTION CAPABILITIES
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      <Categories category={data} />
      <Spacer height={"5rem"} />
      <Categories isTwo={true} category={data2} />
      <Spacer height={"5rem"} />
      <LineHeading/>
      <Comment comment={"The way clubs and the Premier League can use data to enhance the development of elite players and individual growth across all club academies has advanced significantly with this cooperation."}/>
      <Spacer height={"2rem"} />

      <div data-aos="fade-right" className={`${styles?.["line-heading-cont"]} ${styles?.["w-100"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          SEEN & HEARD
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      <VideoSlider slides={videos} />
      {/* <Spacer height={"1rem"} /> */}
      <div className={`${styles?.["flex-column"]} ${styles?.["w-100"]}`}>
        <h1 onClick={()=>navigate("/contact")} className={`${styles?.["orange-background"]} ${styles?.["pointer"]}`}>Contact Us</h1>
      </div>
      <Spacer height={"1rem"} />

    </div>
  );
};
export default Sports;
