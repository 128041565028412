import React from "react";
import styles from "../../styles/main.module.css";
import stats from "../../assets/linewhite-background.png";

const FlexContainer = ({data={},isLeft=false}) => {
  return (
    <div
  data-aos={isLeft?"fade-left":"fade-right"}
      className={`${styles?.["m-auto"]} ${styles?.["w-80"]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
      style={{ gap: "2rem" }}
    >
      {isLeft ? (
        <>
          <div
            style={{ lineHeight: "39px", textAlign: "justify" }}
            className={` ${styles?.["w-50"]}  ${styles?.["value-cover"]} `}
          >
            <div  className={`   ${styles?.["center"]} ${styles?.["w-fit"]}  `} >{data?.title2}</div>
            <h1  className={`${styles?.["head-lg"]} ${styles?.["orange-background"]}  ${styles?.["center"]} ${styles?.["w-fit"]}  `} >{data?.title}</h1>
            <div style={{ lineHeight: "32px", textAlign: "justify" }}>
              {data?.summary}
            </div>
            {/* <div onClick={()=>window.open(link,"_blank")} style={{ color:"#FF6B6B",fontWeight:"500" }}>Read More</div> */}
          </div>

          <div className={`${styles?.["w-50"]} `}>
            <img className={`${styles?.["news-img"]}`} src={data?.img} alt="" />
          </div>
        </>
      ) : (
        <>
          <div className={`${styles?.["w-50"]} `}>
            <img className={`${styles?.["news-img"]}`} src={data?.img} alt="" />
          </div>
          <div
            style={{ lineHeight: "39px", alignItems:"start", textAlign: "justify" }}
            className={`${styles?.["flex-column"]} ${styles?.["w-60"]}  ${styles?.["value-cover"]} `}
          >
            <div  className={`  ${styles?.["center"]} ${styles?.["w-fit"]}  `} >{data?.title2}</div>
            <h1  className={`${styles?.["head-lg"]} ${styles?.["orange-background"]}  ${styles?.["center"]} ${styles?.["w-fit"]}  `} >{data?.title}</h1>
            <div style={{ lineHeight: "32px", textAlign: "justify" }}>
              {data?.summary}
            </div>
            
          </div>
        </>
      )}
    </div>
  );
};
export default FlexContainer;
