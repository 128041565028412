import React from "react";
import styles from "../../styles/main.module.css"
        
const BoldHRWhite = () => {
    return (
        <div className={styles.boldHrWhite}>
             
        </div>
    );
};
export default BoldHRWhite;