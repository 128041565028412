import React from "react";
import styles from "../../styles/main.module.css";
import logo from "../../assets/speed logo.png";
import { redirect, useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className={`${styles?.["flex-between"]} ${styles?.["navbar"]}`}>
      <div
        style={{ gap: "4rem" }}
        className={`${styles?.["flex-gap"]} ${styles?.["w-60"]}`}
      >
        <div
          style={{ padding: "0rem 1rem", height: "2.5rem" }}
          onClick={() => navigate("/")}
          className={`${styles?.["flex-gap"]}  ${styles?.["pointer"]} ${styles?.["white-background"]} ${styles?.["secondary-color"]}    `}
        >
          <div style={{ width: "20px" }}>
            <img style={{ width: "100%" }} src={logo} alt="" />
          </div>
          <div
            className={` ${styles?.["head-sm"]} ${styles?.["secondary-color"]}`}
          >
            SPEEED.AI
          </div>
        </div>
        <div className={`${styles?.["flex-between"]} ${styles?.["w-80"]}`}>
          <div
            onClick={() => navigate("/whyspeed")}
            className={` ${styles?.["pointer"]} ${styles?.["head-sm"]}  ${
              window.location.pathname != "/whyspeed"
                ? styles?.["tab"]
                : styles?.["active-tab"]
            } `}
          >
            Why Speeed.AI
          </div>
          <div className={`${styles?.["dropdown"]}`}>
            <div
              // onClick={() => navigate("/intell/platform")}
              className={` ${styles?.["pointer"]}  ${styles?.["head-sm"]}  ${
                window.location.pathname == "/intell/platform" || window.location.pathname == "/intell/performance/opt"||window.location.pathname == "/intell/performance/med" ||window.location.pathname == "/intell/performance/c&d"
                  ? styles?.["active-tab"]
                  : styles?.["tab"]
              } `}
            >
              {" "}
              Intelligence Platform
            </div>
            <div className={`${styles?.["dropdown-content"]}`}>
              <div
                onClick={()=>navigate("/intell/performance/med")}
                className={` ${
                  window.location.pathname == "/intell/performance/med"
                  ? styles?.["dropdown-item-active"]
                  : styles?.["dropdown-item"]
                } `}
              >
                Performance Medicine
              </div>
              <div
                onClick={()=>navigate("/intell/performance/opt")}

                className={`${
                  window.location.pathname == "/intell/performance/opt"
                    ? styles?.["dropdown-item-active"]
                    : styles?.["dropdown-item"]
                } `}
              >
                

                Performance Optimization
              </div>
              <div
              onClick={()=>navigate("/intell/performance/c&d")}
                className={`${
                  window.location.pathname == "/intell/performance/c&d"
                  ? styles?.["dropdown-item-active"]
                  : styles?.["dropdown-item"]
                } `}
              >
                Coaching & Development
              </div>
            </div>
          </div>
          <div
            onClick={() => navigate("/sports")}
            className={` ${styles?.["pointer"]} ${styles?.["head-sm"]}  ${
              window.location.pathname != "/sports"
                ? styles?.["tab"]
                : styles?.["active-tab"]
            } `}
          >
            {" "}
            Sports
          </div>
          <div
            onClick={() => navigate("/defence")}
            className={` ${styles?.["pointer"]} ${styles?.["head-sm"]}  ${
              window.location.pathname != "/defence"
                ? styles?.["tab"]
                : styles?.["active-tab"]
            } `}
          >
            {" "}
            Defence
          </div>
        </div>
      </div>
      <div className={`${styles?.["flex-gap"]} ${styles?.["head-sm"]} `}>
        {/* <span className={` ${styles?.["pointer"]}`}>Login</span> */}
        <button
          onClick={() => navigate("/contact")}
          className={`${styles?.["white-button"]} ${styles?.["primary-color"]} ${styles?.["req-button"]}`}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};
export default Navbar;
