import React from "react";
import styles from "../../styles/main.module.css";
import background from "../../assets/Home-page/background.png";
import lineWhite from "../../assets/linewhite-background.png";

import logo1 from "../../assets/Brand-logos/iitb1 2.png";
import logo2 from "../../assets/Brand-logos/Stanford-seed-logo-2 1.png";
import logo3 from "../../assets/Brand-logos/Nvidia_logo.svg 1.png";
import logo4 from "../../assets/Brand-logos/sharda1.jpg 1.png";
import logo5 from "../../assets/Brand-logos/sai new1.jpg 1.png";
import logo6 from "../../assets/Brand-logos/Sharda-Hospital-Logo 1.png";
import Spacer from "../common/Spacer";
// import Typewriter from "../common/TypeWriter";
// import VoiceToText from "../common/Voice";
const MainCover = () => {
  const brands = [logo1, logo2, logo3, logo4, logo5, logo6];
  const sentences = [
    "  Helping you re-imagine patient experience​",
    " Streamlining your Hospital Workflows​",
    " Enhancing your Staff Productivity by Automating Routine Tasks​​",
    " Becoming force multipliers overcoming staff shortage​",
    " Empowering your Staff to Focus on Critical Patient Care​​",
    " Available 24X7, an infinitely scalable cost optimized workforce​",
    " Elevating healthcare delivery standards​​",
    " Improving patient experience​​",
  ];
  return (
    <div>
      <div
      
        style={{
          minHeight: "43.0625rem",
          backgroundImage: `url(${background})`,
        }}
        className={`${styles?.["gradiant-blue"]} ${styles?.["cover"]} ${styles?.["backgroundImage"]}  `}
      >
        {/* <div style={{ minHeight:"6rem" }}></div> */}
        <div
      
          style={{ height: "100%" }}
          className={`${styles?.["cover-image"]} `}
          alt=""
        >
          {" "}
        </div>
        <div
          data-aos="fade-up"
          className={`${styles?.["cover-content"]} ${styles?.["flex-column"]} `}
        >
          <div className={`${styles?.["flex-column-end"]} `}>
            <div className={`${styles?.["head-xlg"]} ${styles?.["center"]} `}>
              Unlock the limits <br /> of Human Accomplishment
            </div>
            {/* <div
            className={`${styles?.["gradiant-pink-col"]} ${styles?.["head-lg"]} `}
          >
            HealthMates.AI
          </div> */}
          </div>

          <div className={`${styles?.["head-sm"]} ${styles?.["center"]} ${styles?.["inter"]}  `}>
            The first league-wide records management system in history, <br />{" "}
            ARMS was created by practitioners for practitioners.
          </div>
          {/* <VoiceToText/> */}
          <Spacer height={"10rem"} />
          {/* <div>
            <svg
              width="62"
              height="61"
              viewBox="0 0 82 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M41 81C46.3842 81 51.7157 79.9524 56.69 77.9171C61.6644 75.8818 66.1842 72.8986 69.9914 69.1378C73.7986 65.377 76.8186 60.9124 78.8791 55.9987C80.9395 51.085 82 45.8185 82 40.5C82 35.1815 80.9395 29.915 78.8791 25.0013C76.8186 20.0876 73.7986 15.6229 69.9914 11.8622C66.1842 8.1014 61.6644 5.11819 56.69 3.08288C51.7157 1.04756 46.3842 -7.92524e-08 41 0C30.1261 1.60057e-07 19.6976 4.26695 12.0086 11.8622C4.31963 19.4574 0 29.7587 0 40.5C0 51.2413 4.31963 61.5426 12.0086 69.1378C19.6976 76.733 30.1261 81 41 81ZM35.4559 22.455L61.1674 36.567C61.8773 36.9568 62.4687 37.527 62.8805 38.2186C63.2922 38.9101 63.5093 39.6977 63.5093 40.5C63.5093 41.3023 63.2922 42.0899 62.8805 42.7814C62.4687 43.473 61.8773 44.0432 61.1674 44.433L35.4559 58.545C34.6234 59.0021 33.6844 59.2364 32.7319 59.2247C31.7795 59.213 30.8467 58.9557 30.026 58.4782C29.2052 58.0008 28.525 57.3198 28.0527 56.5027C27.5804 55.6857 27.3324 54.7609 27.3333 53.82V27.18C27.3324 26.2391 27.5804 25.3143 28.0527 24.4973C28.525 23.6802 29.2052 22.9992 30.026 22.5218C30.8467 22.0443 31.7795 21.787 32.7319 21.7753C33.6844 21.7636 34.6234 21.9979 35.4559 22.455Z"
                fill="white"
              />
            </svg>
          </div> */}
          {/* <Spacer height={"1rem"} /> */}

          <div
            className={`${styles?.["flex-between"]} ${styles?.["brand-list"]}`}
          >
            {/* {brands?.map((img) => (
            <>
              <div style={{ width: "80px" }} className={`${styles?.["w-35"]} `}>
                <img className={`${styles?.["w-100"]} `} src={img} alt="" />
              </div>
            </>
          ))} */}
            <div style={{ width: "95px" }} className={`${styles?.["w-35"]} `}>
              <img className={`${styles?.["w-100"]} `} src={logo1} alt="" />
            </div>
            <div style={{ width: "201px" }} className={`${styles?.["w-35"]} `}>
              <img className={`${styles?.["w-100"]} `} src={logo2} alt="" />
            </div>
            <div style={{ width: "100px" }} className={`${styles?.["w-35"]} `}>
              <img className={`${styles?.["w-100"]} `} src={logo3} alt="" />
            </div>
            <div style={{ width: "171px" }} className={`${styles?.["w-35"]} `}>
              <img className={`${styles?.["w-100"]} `} src={logo4} alt="" />
            </div>
            <div style={{ width: "80px" }} className={`${styles?.["w-35"]} `}>
              <img className={`${styles?.["w-100"]} `} src={logo5} alt="" />
            </div>
            <div style={{ width: "130px" }} className={`${styles?.["w-35"]} `}>
              <img className={`${styles?.["w-100"]} `} src={logo6} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ minHeight: "33rem", overflow: "hidden" }}
        className={`${styles?.["cover"]} `}
      >
        <div className={`${styles?.["w-100"]} ${styles?.["whiteline"]} `}>
          <img className={` ${styles?.["w-100"]} `} src={lineWhite} alt="" />
        </div>
        <Spacer height={"8rem"} />
        <div data-aos="fade-up" style={{ gap: ".5rem" }} className={`${styles?.["flex-column"]} `}>
          <div className={`${styles?.["head-lg"]} ${styles?.["center"]} `}>
            It takes hard work to reach <br /> the limits of human
          </div>
          <div
            className={`${styles?.["head-lg"]} ${styles?.["orange-background"]} ${styles?.["center"]} `}
          >
            PERFORMANCE
          </div>
          <Spacer height={".5rem"} />
          <div className={`${styles?.["head-lg"]} ${styles?.["center"]} `}>
            It takes{" "}
            <span
              className={`${styles?.["head-lg"]} ${styles?.["orange-background"]} ${styles?.["center"]} `}
            >
              INTELLIGENCE
            </span>{" "}
            to <br />
            <Spacer height={"1.3rem"} />
            push past them.
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainCover;
