import React from "react";
import styles from "../../styles/main.module.css";
import hipaa from "../../assets/Click on defense/hippa.png"
import gdpr from "../../assets/Click on defense/gdpr.png"
import iso from "../../assets/Click on defense/iso.png"
import pdp from "../../assets/Click on defense/pdp.png"


        
const DefenceSecurity = () => {
    const images=[
        gdpr,pdp,iso,hipaa
    ]
    return (
        <div  data-aos="fade-up"
        className={`${styles?.['flex-column']} ${styles?.['w-80']} ${styles?.['m-auto']}`}>
            <h1 className={`${styles?.['head-xlg']}`} >
            THE  <span className={`${styles?.['orange-background']}`}>HIGHEST STANDARD</span>  OF SECURITY 
            </h1>
            <div style={{ gap:"10px",alignItems:"center"}} className={`${styles?.['flex-center']}  ${styles?.['m-auto']} ${styles?.['w-100']}`}>
                {
                    images?.map((image,i)=>(
                        <div style={{ width:"170px" }} key={i}>
                            <img style={{ width:"100%" }} src={image} alt="" />
                        </div>
                    ))
                }

            </div>
             
        </div>
    );
};
export default DefenceSecurity;