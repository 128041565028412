import React from "react";
        
const Star = () => {
    return (
        <div >
            <svg width="28" height="28" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.45875 35.7936L10.6275 22.5594L0 13.6581L14.04 12.4807L19.5 0L24.96 12.4807L39 13.6581L28.3725 22.5594L31.5413 35.7936L19.5 28.7761L7.45875 35.7936Z" fill="#FFC20E"/>
</svg>

             
        </div>
    );
};
export default Star;