import React from "react";
import styles from "../../styles/main.module.css";
import Spacer from "../common/Spacer";
import left from "../../assets/icon/left-arrow.png";
import { useNavigate } from "react-router-dom";

const Categories = ({category,isTwo=false}) => {
  const navigate=useNavigate()

  return (
    <div
    data-aos="fade-up"
       
    style={{ alignItems: "start" }}
    className={`${styles?.[isTwo?"categories-container2":"categories-container"]} ${styles?.["cover"]} ${styles?.["w-95"]} ${styles?.["m-auto"]}`}
  >
    <hr style={{ width:"100%",left:"0",top:"12%",margin:"0rem -20px" }} className={styles['orange-hr']} />
    {category?.map((data, i) => (
      <>
        <div
          style={{
            height: "100%",
            borderRight:
              i != category.length-1 ? "2px solid white" : "",
          }}
          className={`${styles?.["cover"]}`}
        >
          
          <div
            style={{ gap: "10%", alignItems: "start" }}
            className={` ${styles?.["flex-column-str"]}  ${styles?.["p-r-3"]}`}
          >
            <h1
              className={` ${styles?.["p-l-3"]} ${styles?.["center"]} ${styles?.["w-fit"]}  `}
            >
              {data?.category}
            </h1>
            <p style={{ minHeight:"4rem" }} className={`  ${styles?.["center"]} ${styles?.["head"]} ${styles?.["justify"]}   `}>
              {data?.summary}
            </p>

            <div >
              {data?.features?.map((feature) => (
                <div style={{ minHeight:"8rem" }} className={`${styles?.["p-b-3"]}`}>
                  <div
                    className={`${styles?.["flex-gap"]} ${styles?.[""]} ${styles?.["head-md"]}`}
                  >
                    {feature?.title}
                  </div>
                  <p>{feature?.details}</p>
                </div>
              ))}
            </div>
          </div>
          {
            data?.link!==""?  <div
            onClick={()=>navigate(data?.link)}
              style={{ alignItems: "center",justifyContent:"center" }}
              className={`${styles?.["head-lg"]} ${styles?.["flex-gap"]} ${styles?.["pointer"]} ${styles?.["w-100"]} ${styles?.["learn-more"]}`}
            >
              <div>LEARN MORE</div>
  
              <div style={{ width: "50px" }}>
                <img style={{ width: "100%" }} src={left} alt="" />
              </div>
            </div>:<></>
          }
        
        </div>
        {/* <div style={{ height:"100%",width:"10px", background:"white" }}></div> */}
      </>
    ))}
  </div>
  );
};
export default Categories;
