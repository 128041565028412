import React from "react";
import styles from "../../styles/main.module.css"

        
const CoverContent = ({img="",text="",text2=""}) => {
    return (
        <div className={`${styles?.["cover"]} ${styles?.["w-100"]}`}>
            <img className={` ${styles?.["w-100"]}`}  src={img} alt="" />
             <div className={`${styles?.["cover-content-title"]}  ${styles?.["w-100"]}`}>
                <h1 className={`${styles?.["center"]}  ${styles?.["head-xxlg"]}`}>{text} <br />{text2}</h1>
                
             </div>
        </div>
    );
};
export default CoverContent;