import React from "react";
import styles from "../../styles/main.module.css";
import Spacer from "../common/Spacer";

const ContentBanner = ({ data = {} }) => {
  return (
    <div className={`${styles["flex-column"]} ${styles["m-auto"]}`}>
      <div
        data-aos="fade-up"
        data-aos-delay="1000"

        className={`${styles["orange-background"]} ${styles["head-lg"]} ${styles["w-70"]} ${styles["center"]} `}
      >
        {data?.head1}
      </div>
      <Spacer height="2rem" />
      <div data-aos="fade-up"
        data-aos-delay="1500" style={{ color: "#949494" }} className={styles["head-sm"]}>
        {data?.head2}
      </div>
      <div data-aos="fade-up"
        data-aos-delay="2000" className={styles["head-lg"]}>{data?.head3}</div>
      <div data-aos="fade-up"
        data-aos-delay="2500"  style={{ color: "#949494" }} className={styles["head-sm"]}>
        {data?.head4}
      </div>
    </div>
  );
};
export default ContentBanner;
