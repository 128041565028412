import React from "react";
import styles from "../../styles/main.module.css";

import security from "../../assets/Home Page/security.jpg";
import Spacer from "../common/Spacer";
const SecuritySection = () => {
  return (
    <div
      style={{ backgroundImage: `url(${security})`,padding:"2rem" ,minHeight:"403px"}}
      className={`${styles?.["backgroundImage"]} ${styles?.["w-95"]}  ${styles?.["m-auto"]}`}
    >
      <div
        className={`${styles?.["m-auto"]} ${styles?.["w-95"]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
        style={{ gap: "2rem" }}
      >
        <div className={`${styles?.["w-60"]} `}>
          {/* <img
            //   height=
            style={{ height: "100%" }}
            className={`${styles?.["news"]} ${styles?.["w-100"]}`}
            src={"config"}
            alt=""
          /> */}
        </div>
        <div
          style={{ lineHeight: "39px", textAlign: "justify" }}
          className={` ${styles?.["w-90"]}  ${styles?.["value-cover"]} `}
        >
          <div
            className={` ${styles?.["white-background"]}  ${styles?.["head-lg"]}  ${styles?.["left"]} ${styles?.["head-xlg"]}  `}
          >
            MILLIONS OF AUTOMATIONS,
          </div>
          <Spacer />
          <div
            className={`${styles?.["orange-background"]}  ${styles?.["head-lg"]}  ${styles?.["left"]} ${styles?.["head-xlg"]}  `}
          >
            MAXIMUM SECURITY
          </div>
          <Spacer height="2rem" />

          <div style={{ lineHeight: "24px", textAlign: "justify" }}>
          Speeed.AI is committed to security and compliance, providing complete transparency as well as access to certificates, records, and high-level information about our stringent control adherence.
          </div>
          <Spacer height={"2rem"} />
          {/* <div
            style={{ padding: ".2rem 3rem" }}
            className={`   ${styles?.["white-back-button"]} `}
            //   onClick={() => window.open(link, "_blank")}
          >
            Learn More <span style={{ fontSize: "100px;" }}>&#8594;</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default SecuritySection;
