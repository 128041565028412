import React from "react";
import styles from "../../styles/main.module.css";

import governing from "../../assets/Home Page/gov bod.jpg";
import league from "../../assets/Home Page/leagues.jpg";

import teams from "../../assets/Home Page/teams.jpg";

import athlete from "../../assets/Home Page/athlete.jpg";
import deffense from "../../assets/Home Page/defense.jpg";

import Spacer from "../common/Spacer";
import Card from "../common/Card";
import LineHeading from "./lineHeading";
const WorkWithSection = ({ isMore = false }) => {
  const wb = [
    {
      img: governing,
      tag: "GOVERNING BODIES",
    },
    {
      img: league,
      tag: "LEAGUES",
    },
    {
      img: teams,
      tag: "TEAMS & CLUBS",
    },
    {
      img: athlete,
      tag: "ATHLETES & SPORT",
    },
    {
      img: deffense,
      tag: "DEFENSE",
    },
  ];

  return (
    <div
      className={` ${styles?.["w-90"]} ${styles?.["m-auto"]} ${styles?.["cover"]} `}
    >
      {/* <LineHeading /> */}
      <Spacer height="1rem" />

      <div className={styles.workWith}>
        {wb?.map((data) => (
          <Card data={data} />
        ))}
      </div>
      <Spacer height="2rem" />
      {isMore ?
       <>
          
       <div data-aos="fade-up"  className={`${styles?.["w-60"]} ${styles?.["flex-column"]} ${styles?.["gray-background"]} ${styles?.["m-auto"]}`}>
        <div className={` ${styles?.["orange-background"]} } ${styles?.["head-lg"]} `} >BECOME</div>
        <div className={` ${styles?.[""]} } ${styles?.["head-lg"]} `}>A CLIENT OF SPEEED.AI</div>
        <Spacer/>
        <div className={` ${styles?.["orange-background"]} } ${styles?.["head-md"]} `}>Join Us</div>
       </div>
       <Spacer height="3rem"/>
       </> : <></>}

      <div
        style={{ fontWeight: "400", textAlign: "center" }}
        className={` ${styles?.["w-80"]} ${styles?.["head-md"]} ${styles?.["m-auto"]} ${styles?.["cover"]} `}
      >
        The way clubs and the Premier League can use data to enhance the
        development of elite players and individual growth across all club
        academies has advanced significantly with this cooperation.
      </div>
      <Spacer height="4rem" />
    </div>
  );
};
export default WorkWithSection;
