import React from "react";
import styles from "../styles/main.module.css";
import MainCover from "../components/IntellegencePlatform/MainCover";
import FlexContainer2 from "../components/common/FlexContainer2";
import more from "../assets/Click on Learn more/training plan.jpg";
import startup from "../assets/click on Intelligence platform/google.jpg";
import google2 from "../assets/click on Intelligence platform/google2.jpg";
import CustomCarousel from "../components/common/Slider";
import Tabs from "../components/IntellegencePlatform/Tabs";
import Spacer from "../components/common/Spacer";

import cover from "../assets/Click on Learn more/background.jpg";

import VideoSlider from "../components/common/VideoSlider";
import Comment from "../components/sports/Comment";

const Explore = () => {
  const MainCoverData={
    title1:"More than an EMR. Beyond an athlete management system.",
    title2:"iP: INTELLIGENCE ",
    title3:" PLATFORM",
    summary:"Make intelligence a point of differentiation.The only operating system that integrates all organizations, data, and procedures is Windows.",
    cover:cover
  }
  const videos = [
    {
      img:startup,
      video:
        "https://x.com/i/status/1816851062147703188",
      summary:
        "All eyes on #ParisOlympics2024—but, back in India,@iitmadras has its sights set on #Olympics 2036. At #IITMSportsTechConclave, the institute said it will incubate 300 sports tech #startups to enable Indian athletes close the gap with global frontrunners.@akhil_edit reports",
      link:"https://x.com/CNBCTV18News/status/1816851062147703188?t=xknZdPkiVdVvnlz9yoH5WA&s=08"
      
    },
    {
      img:google2,
      video:
        "https://videos.pexels.com/video-files/6338366/6338366-uhd_2560_1440_25fps.mp4",
      summary:
        "Digital Darwin Partners with Google to Enhance AI Innovation in Sports and Healthcare",
      link:"https://cxotoday.com/interviews/digital-darwin-revolutionizing-sports-tech-with-computer-vision-generative-ai-and-integrated-analytics/#google_vignette"

    },
    {
      img:startup,
      video:
        "https://videos.pexels.com/video-files/5972853/5972853-uhd_2560_1440_30fps.mp4",
      summary:
        "Digital Darwin: Revolutionizing Sports Tech with Computer Vision, Generative AI, and Integrated Analytics",
      link:"https://ians.in/vmpl/digital-darwin-partners-google-enhance-ai-innovation-sports-healthcare"

    },
  ];
  const WhatsDo = [
    {
      title: "SPEEED.AI’S LABS’ iP INTELLIGENCE PLATFORM:",
      title2: "",
      img: more,
      summary:
        "A centralized cloud-based platform that unifies hundreds of vendors and data sources and aggregates thousands of data points into a single, interactive, cutting-edge operating system for your whole company.",
    },
  ];
  const tabData = [
    {
      tabName: "Performance Medicine",
      pages: [
        {
          page1: "",
          data: [
            {
              title: "Medical History",
              summary:
                "This cutting-edge, centralized, user-friendly medical center offers a comprehensive, rich perspective of the player's medical history, present condition, and ongoing therapy. includes all records of illnesses, injuries, chronic conditions, allergies, procedures, diagnostics, drugs, treatments, and related notes.",
            },
            {
              title: "Configurable Meta Data",
              summary:
                "Configure and compile certain injury-related data along with the environment and conditions that surround it. Gather data specific to each kind of injury, acquire a detailed understanding of those injuries, and learn how to treat them.",
            },
            {
              title: "Screening & Testing",
              summary:
                "Concussion baselines, concussion evaluations, and other medical test protocols are among the screenings for which data and qualitative feedback should be tracked, reported, and alerted about.",
            },
            {
              title: "Player Wellness",
              summary:
                "Keep tabs on the overall health, mental, nutritional, and recuperation status of your players.",
            },
            {
              title: "Player App",
              summary:
                "Staff and coaches can see player status and notifications via a smartphone app.",
            },
            {
              title: "Coach App",
              summary:
                "Players can input details on their health and wellbeing status via a smartphone app.",
            },
          ],
        },
        {
          page2: "",
          data: [
            {
              title: "Export Medical Record",
              summary:
                "Export all of an athlete's medical information as a zip file, including a PDF output of all of their diagnoses, injuries, diseases, notes, treatments, and pictures.",
            },
            {
              title: "Forms & Data collection",
              summary:
                "Technology that may be configured to combine data and input from coaches, players, and staff via the internet, mobile devices, or kiosks.",
            },
            {
              title: "Therapeutic Use Exemption (TUE)",
              summary:
                "An objective assessment of the actual effects of injuries on game and training availability can be obtained by using a flexible system to monitor, communicate, and analyze player availability due to illness, injury, and time off.",
            },
            {
              title: "Medication Management & Governance",
              summary:
                "Keep track of any substance use exemptions granted to athletes.",
            },


          ],
        },
      ],
    },
    {
      tabName: "Performance Optimization",
      pages: [
        {
          page1: "",
          data: [
            {
              title: "Game Participation & Collection",
              summary:
                "Monitor position-specific game minutes, score, and see contextual information such as if a player played up or down an age level.",
            },
            {
              title: "Forms & Data Collection",
              summary:
                "Adaptable solution to collect data and feedback from staff, coaches, and players via the internet, kiosks, or mobile devices.",
            },
            {
              title: "Metric Management & Analysis",
              summary:
                "Organize all of your training, fitness, testing, and other data in one place, and set up how you want to generate, see, and control the metrics related to that data.",
            },
            {
              title: "Player Engagement",
              summary:
                "Players can check their personalized performance and training goal profiles and manually log data on their end through the Player App.",
            },

            {
              title: "Assessments & Benchmarking",
              summary:
                "Adaptable tool for gathering evaluations, ratings, testing and assessment data, and more via a computer, tablet, or phone; it allows for both organized quantitative scoring and qualitative input to guarantee that all relevant information about a player is recorded and accessible. minimizes manual administration and provides real-time access to vital input for all departments and players.",
            },
            {
              title: "Growth & Maturation",
              summary:
                "Gather information on academy athletes' development and growth while monitoring puberty-related factors. Make wiser choices regarding health, training, and retain/release.",
            },
          ],
        },
        {
          page2: "",
          data: [
            {
              title: "Wellness & RPE Collection",
              summary:
                "To help staff identify areas where athletes may require attention, interventions, or adjustments, gather information from players via their smartphone or an on-site kiosk regarding their physical and mental health, recuperation, and training feedback.You can use configurable forms to get various data from players at different times of day or week.",
            },
            {
              title: "Activity Calendar ",
              summary:
                "From the high level to the specifics, see a centralized timetable of what is being done and when at the team, player, coach, and practitioner levels.",
            },
            {
              title: "Coach App",
              summary:
                "Staff and coaches can see player status and notifications via a smartphone app.",
            },
            {
              title: "Medication Management & Governance",
              summary:
                "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
            },

            {
              title: "Player App",
              summary:
                "Players can input details on their health and wellbeing status via a smartphone app..",
            },
          ],
        },
      ],
    },
    {
      tabName: "Coaching & Development",
      pages: [
        {
          page1: "",
          data: [
            {
              title: "Drill Library",
              summary:
                "A systematic, standardized catalog of drills will make training plan creation easier and guarantee uniform standards for tactical and technical improvement.",
            },
            {
              title: "Centralized Scheduling",
              summary:
                "Simplify and consolidate all scheduling and communication for games, training, instruction, and other events.",
            },
            {
              title: "Game Event Capture",
              summary:
                "Record the team's and individual player's minutes in position, goals, cards, assists, and substitutions using an intuitive pitch view.",
            },
            {
              title: "Session & Game Evaluation",
              summary:
                "Gathering a coach's qualitative input on practices and games will help with training plan analysis and match preparation.",
            },

            {
              title: "Participation Management",
              summary:
                "With thorough participation tracking, you can get a comprehensive view of player availability and the amount of time spent playing and training.",
            },
            {
              title: "Reporting & Analysis",
              summary:
                "Visual dashboards for coaching and session analysis, game performance, pipeline analysis, individual development, and top-to-bottom talent assessment, among other things",
            },
          ],
        },
        {
          page2: "",
          data: [
            {
              title: "Session Game collection",
              summary:
                "Connect games and training sessions to objective metrics and subjective evaluations from other systems, such as GPS or match data solutions.",
            },
            {
              title: "Player Status & Alarm",
              summary:
                "A customisable, condensed summary of important player data and player status versus those measures allows you to see which players require attention.",
            },
            {
              title: "Form & Data Collection",
              summary:
                "Adaptable solution to collect data and feedback from staff, coaches, and players via the internet, kiosks, or mobile devices.",
            },
            {
              title: "Coach App",
              summary:
                "Staff and coaches can see player status and notifications via a smartphone app.",
            },

            {
              title: "Player App",
              summary:
                "The home of the athlete. Where athletes can see their key statistics, schedules, and messages. Submit individual sessions, wellness, RPE, and keep up to date",
            },
          ],
        },
      ],
    },
    // {
    //   tabName: "League Operations",
    //   pages: [
    //     {
    //       page1: "",
    //       data: [
    //         {
    //           title: "Injury lifecycle Management",
    //           summary:
    //             "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
    //         },
    //         {
    //           title: "Treatment & rehab Management",
    //           summary:
    //             "Create, edit, and share preventative maintenance and individual care plans quickly and simply in a single, adaptable portal.",
    //         },
    //         {
    //           title: "Exposure& Availability Management",
    //           summary:
    //             "An objective assessment of the actual effects of injuries on game and training availability can be obtained by using a flexible system to monitor, communicate, and analyze player availability due to illness, injury, and time off.",
    //         },
    //         {
    //           title: "Medication Management & Governance",
    //           summary:
    //             "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
    //         },

    //         {
    //           title: "Reporting & Injury Surveillance",
    //           summary:
    //             "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
    //         },
    //         {
    //           title: "Player Profile & Status",
    //           summary:
    //             "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
    //         },
    //       ],
    //     },
    //     {
    //       page2: "",
    //       data: [
    //         {
    //           title: "Injury lifecycle Management",
    //           summary:
    //             "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
    //         },
    //         {
    //           title: "Treatment & rehab Management",
    //           summary:
    //             "Create, edit, and share preventative maintenance and individual care plans quickly and simply in a single, adaptable portal.",
    //         },
    //         {
    //           title: "Exposure& Availability Management",
    //           summary:
    //             "An objective assessment of the actual effects of injuries on game and training availability can be obtained by using a flexible system to monitor, communicate, and analyze player availability due to illness, injury, and time off.",
    //         },
    //         {
    //           title: "Medication Management & Governance",
    //           summary:
    //             "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
    //         },

    //         {
    //           title: "Reporting & Injury Surveillance",
    //           summary:
    //             "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
    //         },
    //         {
    //           title: "Player Profile & Status",
    //           summary:
    //             "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];
  return (
    <div
      style={{ gap: "3rem", alignItems: "start" }}
      className={`${styles?.["flex-column"]}`}
    >
      <MainCover data={MainCoverData} />
      <FlexContainer2
        cornerWidth={"250px"}
        isLeft={true}
        cornerHeight={"350px"}
        data={WhatsDo[0]}
      />
      <div className={`${styles?.["line-heading-cont"]} ${styles?.["w-100"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-xlg"]}`}
        >
          CORE SOLUTIONS
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      <Tabs tabData={tabData} />
      <Spacer height={"5rem"} />
      <div className={`${styles?.["line-heading-cont"]} ${styles?.["w-100"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          SEEN & HEARD
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      <VideoSlider slides={videos} />
      <Spacer height={"3rem"} />

      
      <Comment comment={"The way clubs and the Premier League can use data to enhance the development of elite players and individual growth across all club academies has advanced significantly with this cooperation."}/>
      <Spacer height={"5rem"} />
    </div>
  );
};
export default Explore;
