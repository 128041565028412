import React from "react";
import styles from "../../styles/main.module.css";
        
const LineHeading = () => {
    return (
        <div className={` ${styles?.["w-100"]}`}>
        <div
     style={{ justifyContent: "center",marginBottom:"-12px" }}
          className={` ${styles?.["flex-gap"]}  `}
        >
          <h1 className={`${styles?.["head-lg"]}`}>
            EVERY{" "}
            <span className={`${styles?.["orange-background"]}`}>SPORT</span>
          </h1>
        </div>
        <div
          className={`${styles?.["line-heading-cont"]} ${styles?.["w-100"]}`}
        >
          <div  style={{ width:"42%" }} className={`${styles?.["line-heading"]}`}></div>
          <div
            className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
          ></div>
          <div style={{ width:"42%" }} className={`${styles?.["line-heading"]}`}> </div>
        </div>
        <div
          style={{ justifyContent: "center",marginTop:"-12px" }}
          className={` ${styles?.["flex-gap"]}  `}
        >
          <h1 className={`${styles?.["head-lg"]}`}>
            EVERY{" "}
            <span className={`${styles?.["orange-background"]}`}>LEVEL</span>
          </h1>
        </div>
        
      </div>
    );
};
export default LineHeading;