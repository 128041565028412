import React from "react";
import styles from "../../styles/main.module.css"
        
const LineHeading = ({text}) => {
    return (
        <div className={`${styles?.["line-heading-cont"]}`}>
            <div className={`${styles?.["line-heading"]}`}></div>
            <div className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}>WHAT DOES <span className={`${styles?.["head-xlg"]}`}> SPEEED.AI </span> DO?</div>
            <div className={`${styles?.["line-heading"]}`}> </div>
             
        </div>
    );
};
export default LineHeading;