import React from "react";
import styles from "../styles/main.module.css";
import MainCover from "../components/IntellegencePlatform/MainCover";
import Spacer from "../components/common/Spacer";
import cover from "../assets/Click on defense/Group 1718.jpg";
import ContentBanner from "../components/sports/ContentBanner";
import Comment from "../components/sports/Comment";
import LineHeading from "../components/defence/lineHeading";
import DefenceFlex from "../components/defence/DefenceFlex";
import arymMan from "../assets/Click on defense/armyman.jpg";
import airforce from "../assets/Click on defense/airforce.jpg";

import army from "../assets/Click on defense/army.jpg";

import armyFlag from "../assets/Click on defense/army-flag.jpg";

import armyTraining from "../assets/Click on defense/army-training.jpg";

import armyTraining2 from "../assets/Click on defense/army-training2.jpg";
import figher from "../assets/Click on defense/figher-plane.jpg";
import DefenceGrid from "../components/defence/DefenceGrid";
import PerformanceSection from "../components/defence/PerformanceSection";
import DefenceSecurity from "../components/defence/DefenceSecurity";



const Defence = () => {
  const defenceFlex = [
    {
      title: "Service Member Portfolio",
      // title: "PLAY SMARTER",
      summary:
        "From hiring until retirement, keep a precise record of each service member's credentials, growth, training, performance, and accomplishments..",
      features: [
        {
          title: "CAREER SERVICE PORTFOLIO",
          details:
            "To expedite yearly performance reporting, promotions, or selection procedures, view each service member's current status together with their achievements and career transitions at each level.",
        },
        {
          title: "PERFORMANCE PROFILE",
          details:
            "Documentation of every performance history in terms of technical/tactical, cognitive, and physical variables.",
        },
        {
          title: "MEDICAL PROFILE",
          details:
            "A thorough and comprehensive view of all medical history and electronic information.",
        },
        {
          title: "HISTORICAL ANALYSIS",
          details:
            "To comprehend trends, advancements, or problems, go deeply into any area of the service member profile.",
        },
      ],
      img:arymMan
    
    },
    {
      title: "Service Member Development & Optimization",
      // title: "OPTIMIZE YOUR HUMAN ASSETS",
      summary:
        "Increase the number of service personnel of a higher caliber by scaling and systemizing development programs to equip them to operate at the peak of their physical, tactical, and cognitive abilities.",
      features: [
        {
          title: "INDIVIDUAL DEVELOPMENT PLANS",
          details:
            "To achieve the intended results, evaluate the data strategy and process, pinpoint areas for improvement, and put systems in place.",
        },
        {
          title: "CURRICULUM & TRAINING PLANS",
          details:
            "A history of performance on all technical/tactical, cognitive, and physical criteria.",
        },
        {
          title: "MEDICAL PROFILE",
          details:
            "A thorough and comprehensive view of all medical history and electronic information.",
        },
        {
          title: "HISTORICAL ANALYSIS",
          details:
            "Investigate any area of the service member profile in depth to learn about problems, trends, or advancements.",
        },
      ],
      img:armyTraining
    },
    {
      title: "Force readiness",
      // title: "OPTIMIZE YOUR HUMAN ASSETS",
      summary:
        "To support well-informed decision-making, evaluate and monitor force preparedness in operational units and training pipelines in real-time.",
      features: [
        {
          title: "STATUS AND ALERTS",
          details:
            "Centralized assessment of service member preparedness, according to particular standards for every profession.",
        },
        {
          title: "LEADERSHIP DASHBOARDS",
          details:
            "Make it simple for leaders to decide if teams or individuals are prepared for missions or training iterations.",
        },
        {
          title: "INSTRUCTOR & PRACTITIONER DASHBOARDS",
          details:
            "A thorough and comprehensive view of all medical history and electronic information.",
        },
        {
          title: "OPERATIONAL RISK MANAGEMENT DASHBOARD",
          details:
            "Provides leadership with concise, unbiased information regarding the risk levels of service members so they may decide whether to send each person on missions and training.",
        },
      ],
      img:army
    },
  ];
  const content = {
    head1:
      "In Defence, Readiness means Security",
    head2: "FROM PRIMED SOLDIERS...",
    head3: "TO INTREPID UNITS...",
    head4: "TO SEAMLESS COMMUNICATION ACROSS DEPARTMENTS...",
  };
  const MainCoverData = {
    title1: "DEFENCE MANAGEMENT SOFTWARE",
    title2: "DEFENCE ",
    title3: "",
    summary:
      "Optimize the performance and return on your most important assets – service members – both at the individual level and across the force.",
    cover: cover,
  };
  const data={
    title: "BORN IN HUMAN PERFORMANCE",
      // title: "PLAY SMARTER",
      summary:
        "Speeed.AI offers previously unheard-of levels of competence in force optimization and human performance.",
      features: [
       "Utilize Speeed.AI vast experience in the field to optimize performance standards unique to the defense industry.",
       "Utilize important insights and best practices from other high-performing, pertinent industries, such as elite sports.",
       "When a system is adaptable and customizable, you may acquire the results you require for any given circumstance.",
      ],
      img:armyFlag
  }
  const DefecenceGridData=[
    {
      img:armyTraining2,
      summary:"Throughout each service member's lifespan, from hiring and training to active duty and retirement, Speeed.AI maximizes performance and prolongs careers."
    },
    {
      img:airforce,
      summary:"Support all forms of service, from normal military duty to Special Operations Forces, whether on the front lines or in the office."
    },
    {
      img:figher,
      summary:"Get a comprehensive picture of the whole service member, including their technical and tactical skills as well as their cognitive and physical performance."
    },
  ]
  return (
    <div
      style={{ gap: "4rem", alignItems: "start" }}
      className={`${styles?.["flex-column"]}`}
    >
      <MainCover data={MainCoverData} />
      <ContentBanner data={content} />

      <Spacer height={"3rem"} />
      <div className={`${styles?.["line-heading-cont"]} ${styles?.["w-100"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          DEFENSE OFFERINGS
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      {
        defenceFlex.map((data,i)=>(
          <DefenceFlex data={data} isLeft={i%2==0?true:false}/>
        ))
      }
      <Spacer height={"1rem"} />
      <LineHeading/>
  
      <h1  className={`${styles?.["orange-background"]} ${styles?.["center"]} ${styles?.["m-auto"]}`}>
        
      The full service member lifecycle is supported <br /> by Speeed.AI in every performance domain.
      </h1>

      {/* <Spacer height={"2rem"} /> */}
      <DefenceGrid Categories={DefecenceGridData}/>
      <PerformanceSection isLeft={true} data={data}/>
      
      <DefenceSecurity/>
      <Spacer height={"2rem"} />
    </div>
  );
};
export default Defence;
