import React, { useState } from "react";
import "../../styles/slider.css";
import rightArrow from "../../assets/icon/left-arrow.png";
import styles from "../../styles/main.module.css";
import ground1 from "../../assets/Home Page/ground2.jpg";

import leftArrow from "../../assets/icon/right-arrow.png";

const CustomCarousel = ({ slides = [] }) => {
  //   const slides = [
  //     "https://via.placeholder.com/800x300/FF0000/FFFFFF?text=Slide+1",
  //     "https://via.placeholder.com/800x300/00FF00/FFFFFF?text=Slide+2",
  //     "https://via.placeholder.com/800x300/0000FF/FFFFFF?text=Slide+3",
  //   ];
  // const slides=[
  //     {
  //         text:"hello"
  //     },
  //     {
  //         text:"hello2"
  //     },
  //     {
  //         text:"hello3"
  //     },
  // ]

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="carousel-container">
      {/* Carousel Slide */}
      <div className="carousel-slide">
        <div className="slide-container">
          {slides[currentSlide]?.data?.map((item) => (
            <div
              style={{ height: "100%", justifyContent: "start" }}
              className={`${styles?.["flex-column"]}`}
            >
              <div></div>
              <div>
                <h2
                  style={{
                    minHeight: "4rem",
                    display: "flex",
                    alignItems: "start",
                  }}
                  className={`${styles?.["flex-gap"]} ${styles?.["left"]} ${styles?.["inter"]} `}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 41 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.84125 39L11.1725 24.5803L0 14.8816L14.76 13.5987L20.5 0L26.24 13.5987L41 14.8816L29.8275 24.5803L33.1587 39L20.5 31.3539L7.84125 39Z"
                      fill="white"
                    />
                  </svg>
                  {item.title}
                </h2>
                <p style={{ textAlign: "left" }}>{item.summary}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Arrows and Dots */}
      {slides.length > 1 ? (
        <div className="carousel-controls">
          <button className="arrow-btn" onClick={prevSlide}>
            <div style={{ width: "80px" }}>
              <img style={{ width: "100%" }} src={leftArrow} alt="" />
            </div>
          </button>
          <div className="dots">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`dot ${currentSlide === index ? "active" : ""}`}
                onClick={() => goToSlide(index)}
              ></div>
            ))}
          </div>
          <button className="arrow-btn" onClick={nextSlide}>
            <div style={{ width: "80px" }}>
              <img style={{ width: "100%" }} src={rightArrow} alt="" />
            </div>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomCarousel;
