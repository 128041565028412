import React, { useState } from "react";
import "../../styles/VideoSlider.css";
import rightArrow from "../../assets/icon/left-arrow.png";
import play from "../../assets/click on Intelligence platform/play.png";

import styles from "../../styles/main.module.css";

import leftArrow from "../../assets/icon/right-arrow.png";

const VideoSlider = ({ slides = [] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationDirection, setAnimationDirection] = useState("");

  const changeSlide = (direction) => {
    if (isAnimating) return; // Prevent rapid slide changes during animation
    setIsAnimating(true);
    setAnimationDirection(direction);

    setTimeout(() => {
      setIsAnimating(false);
      if (direction === "next") {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      } else {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
      }
    }, 500); // Match animation duration
  };

  const prevSlide = () => changeSlide("prev");
  const nextSlide = () => changeSlide("next");

  const goToSlide = (index) => {
    if (isAnimating || index === currentSlide) return;
    setAnimationDirection(index > currentSlide ? "next" : "prev");
    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
      setCurrentSlide(index);
    }, 500);
  };

  return (
    <div data-aos="fade-up" id="carousel-container2">
      {/* Carousel Slide */}
      <div className="carousel-slide">
        <div
          className={`player-wrapper ${
            isAnimating
              ? animationDirection === "next"
                ? "slide-in-right"
                : "slide-in-left"
              : ""
          }`}
        >
          <div
            onClick={() => window.open(slides[currentSlide]?.link, "_blank")}
            className={`${styles?.["pointer"]}`}
            style={{ width: "60%", margin: "auto" }}
          >
            <img
              style={{ width: "100%" }}
              src={slides[currentSlide]?.img}
              alt=""
            />
          </div>
          <div
          style={{
            textAlign: "left",
            fontSize: "1.3rem",
            padding: "2rem 2rem",
          }}
        >
          {slides[currentSlide]?.summary}
        </div>
        </div>
        
      </div>

      {/* Arrows and Dots */}
      <div className="carousel-controls">
        <button className="arrow-btn-left" onClick={prevSlide}>
          <div style={{ width: "80px" }}>
            <img style={{ width: "100%" }} src={leftArrow} alt="" />
            <div
              style={{ minWidth: "max-content" }}
              className={`${styles?.["head-lg"]} ${styles?.["primary-color"]}`}
            >
              PREV
            </div>
          </div>
        </button>
        <div className="dots">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`dot ${currentSlide === index ? "active" : ""}`}
              onClick={() => goToSlide(index)}
            ></div>
          ))}
        </div>
        <button className="arrow-btn-right" onClick={nextSlide}>
          <div style={{ width: "80px" }}>
            <img style={{ width: "100%" }} src={rightArrow} alt="" />
            <div
              style={{ minWidth: "max-content" }}
              className={`${styles?.["head-lg"]} ${styles?.["primary-color"]}`}
            >
              NEXT
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default VideoSlider;
