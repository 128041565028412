import React from "react";
import styles from "../../styles/main.module.css";
import stats from "../../assets/linewhite-background.png";
import Star from "./Star";

const DefenceFlex = ({ data = {}, isLeft = false }) => {
  return (
    <div
      className={`${styles?.["m-auto"]} ${styles?.[""]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
      style={{ gap: "2rem" }}
    >
      {isLeft ? (
        <>
          <div
            style={{
              lineHeight: "22px",
              textAlign: "justify",
              marginLeft: "4rem",
            }}
            className={` ${styles?.["w-70"]}  ${styles?.["value-cover"]} `}
          >
            <h1
              className={`${styles?.["head-xlg"]} ${styles?.[""]}   ${styles?.["left"]} ${styles?.["w-fit"]}  `}
            >
              {data?.title}
            </h1>
            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              {data?.summary}
            </p>
            <div
              style={{ margin: 0 }}
              className={`${styles?.["secure-grid"]} `}
            >
              {data?.features?.map((feature, i) => (
                <div data-aos="fade-up"
                data-aos-delay="2000" key={i}>
                  <div className={`${styles?.["flex-gap"]}    `}>
                    <Star />
                    <h2
                      className={`   ${styles?.["left"]} ${styles?.["w-fit"]}  `}
                    >
                      {feature?.title}
                    </h2>
                  </div>
                  <div>{feature?.details}</div>
                </div>
              ))}
            </div>
          </div>

          <div className={`${styles?.["defenceflex-img"]} `}>
            <img className={`${styles?.["w-100"]} `} src={data?.img} alt="" />
          </div>
        </>
      ) : (
        <>
          <div className={`${styles?.["defenceflex-img"]} `}>
            <img className={`${styles?.["w-100"]} `} src={data?.img} alt="" />
          </div>
          <div
            style={{
              lineHeight: "22px",
              textAlign: "justify",
              marginRight: "4rem",
            }}
            className={` ${styles?.["w-70"]}  ${styles?.["value-cover"]} `}
          >
            <h1
              className={`${styles?.["head-xlg"]} ${styles?.[""]}   ${styles?.["left"]} ${styles?.["w-fit"]}  `}
            >
              {data?.title}
            </h1>
            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              {data?.summary}
            </p>
            <div
              style={{ margin: 0 }}
              className={`${styles?.["secure-grid"]} `}
            >
              {data?.features?.map((feature, i) => (
                <div data-aos="fade-up"
                data-aos-delay="2000" key={i}>
                  <div className={`${styles?.["flex-gap"]}    `}>
                    <Star />
                    <h2
                      className={`   ${styles?.["left"]} ${styles?.["w-fit"]}  `}
                    >
                      {feature?.title}
                    </h2>
                  </div>
                  <div>{feature?.details}</div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default DefenceFlex;
