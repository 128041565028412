import React from "react";
import styles from "../styles/main.module.css";
import MainCover from "../components/IntellegencePlatform/MainCover";
import FlexContainer2 from "../components/common/FlexContainer2";
import more from "../assets/click on Intelligence platform/plan for every possiblitity.jpg";
import CustomCarousel from "../components/common/Slider";
import Tabs from "../components/IntellegencePlatform/Tabs";
import Spacer from "../components/common/Spacer";
import VideoSlider from "../components/common/VideoSlider";
import cover from "../assets/click on Intelligence platform/performance optimization.jpg";
import startup from "../assets/click on Intelligence platform/google.jpg";
import google2 from "../assets/click on Intelligence platform/google2.jpg";
import vision from "../assets/click on Intelligence platform/computer-vision.png";



const PerformanceOpt= () => {
  const MainCoverData={
    title1:"ATHLETE MANAGEMENT SYSTEM (AMS)",
    title2:"PERFORMACE ",
    title3:"OPTIMIZATION",
    summary:"With tools and insights that provide you with a comprehensive picture of each athlete, you can prepare them for the particular demands of your team's approach.",
    cover:cover
  }
  const videos = [
    {
      img:startup,
      video:
        "https://x.com/i/status/1816851062147703188",
      summary:
        "All eyes on #ParisOlympics2024—but, back in India,@iitmadras has its sights set on #Olympics 2036. At #IITMSportsTechConclave, the institute said it will incubate 300 sports tech #startups to enable Indian athletes close the gap with global frontrunners.@akhil_edit reports",
      link:"https://x.com/CNBCTV18News/status/1816851062147703188?t=xknZdPkiVdVvnlz9yoH5WA&s=08"
      
    },
    {
      img:google2,
      video:
        "https://videos.pexels.com/video-files/6338366/6338366-uhd_2560_1440_25fps.mp4",
      summary:
        "Digital Darwin Partners with Google to Enhance AI Innovation in Sports and Healthcare",
      link:"https://cxotoday.com/interviews/digital-darwin-revolutionizing-sports-tech-with-computer-vision-generative-ai-and-integrated-analytics/#google_vignette"

    },
    {
      img:vision,
      video:
        "https://videos.pexels.com/video-files/5972853/5972853-uhd_2560_1440_30fps.mp4",
      summary:
        "Digital Darwin: Revolutionizing Sports Tech with Computer Vision, Generative AI, and Integrated Analytics",
      link:"https://ians.in/vmpl/digital-darwin-partners-google-enhance-ai-innovation-sports-healthcare"

    },
  ];
  const WhatsDo = [
    {
      title: "MORE TIME ON THE FEILD",
      title2: "PLAN FOR EVERY POSSIBILITY",
      img: more,
      summary:
        "Integrate your medical data with performance data, such as game, training, gym, physical testing, and more, to get a comprehensive picture of your athletes' current and past states as well as their future goals.",
    },
  ];
  const tabData = [
    {
      tabName: "CORE CAPABILITIES",
      pages: [
        {
          page1: "",
          data: [
            {
              title: "Session Planning",
              summary:
                "Create and monitor thorough training schedules that are in line with the distinct playing style of your team. Streamline internal communications and get everyone on the same page regarding the objectives of each meeting.",
            },
            {
              title: "Player Status",
              summary:
                "A customisable, condensed summary of important player data and player status versus those measures allows you to see which players require attention.",
            },
            {
              title: "Group Reporting & Analytics",
              summary:
                "Get a comprehensive picture of group activities, trends, and current conditions for training, recovery, wellbeing, and performance in games. Sorting and managing all of the data is made simple by your organization's configurable reporting and analysis of different position levels.",
            },
            {
              title: "Participation Management",
              summary:
                "Using a customizable management system, track, communicate, and evaluate player involvement levels in training and games, whether they are full, modified, or partial.",
            },

            {
              title: "Player Profiling & Benchmarking",
              summary:
                "To determine the actual effect of injuries on game and training availability, track and share player availability due to illness, injury, or time off. You may also assess injury load.",
            },
            {
              title: "Assessment & Testing ",
              summary:
                "Gather evaluations, reviews, test and assessment results from coaches, medical staff, performance staff, and others using a computer, tablet, or phone. ",
            },
          ],
        },
      ],
    },
    // {
    //   tabName: "FEATURE ADD ONS",
    //   pages: [
    //     {
    //       page1: "",
    //       data: [
    //         {
    //           title: "Injury lifecycle Management",
    //           summary:
    //             "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
    //         },
    //         {
    //           title: "Treatment & rehab Management",
    //           summary:
    //             "Create, edit, and share preventative maintenance and individual care plans quickly and simply in a single, adaptable portal.",
    //         },
    //         {
    //           title: "Exposure& Availability Management",
    //           summary:
    //             "An objective assessment of the actual effects of injuries on game and training availability can be obtained by using a flexible system to monitor, communicate, and analyze player availability due to illness, injury, and time off.",
    //         },
    //         {
    //           title: "Medication Management & Governance",
    //           summary:
    //             "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
    //         },

    //         {
    //           title: "Reporting & Injury Surveillance",
    //           summary:
    //             "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
    //         },
    //         {
    //           title: "Player Profile & Status",
    //           summary:
    //             "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];
  return (
    <div
      style={{ gap: "3rem", alignItems: "start" }}
      className={`${styles?.["flex-column"]}`}
    >
      <MainCover data={MainCoverData}/>
      <FlexContainer2
        cornerWidth={"360px"}
        cornerHeight={"324px"}
        data={WhatsDo[0]}
      />
      <Tabs tabData={tabData} />
      <Spacer height={"5rem"} />
      <div className={`${styles?.["line-heading-cont"]} ${styles?.["w-100"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          SEEN & HEARD
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      <VideoSlider slides={videos} />
      <Spacer height={"5rem"} />
    </div>
  );
};
export default PerformanceOpt;
