import React from "react";
import styles from "../../styles/main.module.css";
import right from "../../assets/Click on why speed/right.png";
import left from "../../assets/Click on why speed/left.png";

const Comment = ({ comment }) => {
  return (
    <div data-aos="fade-up" className={`${styles["comment-cover"]}`}>
      <div className={`${styles["comment-left"]}`}>
        <img style={{ width: "100%" }} src={left} alt="" />
      </div>
      
      <div>{comment}<div className={`${styles["comment-right"]}`}>
        <img style={{ width: "100%" }} src={right} alt="" />
      </div></div>
    </div>
  );
};
export default Comment;
