import React from "react";
import styles from "../../styles/main.module.css";
import stats from "../../assets/linewhite-background.png";
import Star from "./Star";
import { useNavigate } from "react-router-dom";

const PerformanceSection = ({ data = {}, isLeft = false }) => {
  const navigate=useNavigate()
  return (
    <div>
      <div
        className={`${styles?.["m-auto"]} ${styles?.[""]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
        style={{ gap: "2rem" }}
      >
        {isLeft ? (
          <>
            <div
              style={{
                lineHeight: "22px",
                textAlign: "justify",
                marginLeft: "4rem",
              }}
              className={` ${styles?.["w-70"]}  ${styles?.["value-cover"]} `}
            >
              <h1
                className={`${styles?.["head-xlg"]} ${styles?.[""]}   ${styles?.["left"]} ${styles?.["w-fit"]}  `}
              >
                {data?.title}
              </h1>
              <p style={{ lineHeight: "22px", textAlign: "justify" }}>
                {data?.summary}
              </p>
              <ul style={{ margin: 0 }} className={`${styles?.[""]} `}>
                {data?.features?.map((feature, i) => (
                  <li  data-aos="fade-up"
                  data-aos-delay={`${i+1}00`} className={`${styles?.["p-b-3"]} `} key={i}>
                    {feature}
                  </li>
                ))}
              </ul>
              <div
              onClick={()=>navigate("/contact")}
                className={`${styles?.["flex-column"]} ${styles?.["pointer"]} ${styles?.["w-100"]}`}
              >
                <h1 className={`${styles?.["orange-background"]}`}>
                  Contact Us
                </h1>
              </div>
            </div>

            <div
              style={{ width: "40%" }}
              className={`${styles?.["defenceflex-img"]} `}
            >
              <img className={`${styles?.["w-100"]} `} src={data?.img} alt="" />
            </div>
          </>
        ) : (
          <>
            <div className={`${styles?.["defenceflex-img"]} `}>
              <img className={`${styles?.["w-100"]} `} src={data?.img} alt="" />
            </div>
            <div
              style={{
                lineHeight: "22px",
                textAlign: "justify",
                marginLeft: "4rem",
              }}
              className={` ${styles?.["w-70"]}  ${styles?.["value-cover"]} `}
            >
              <h1
                className={`${styles?.["head-xlg"]} ${styles?.[""]}   ${styles?.["left"]} ${styles?.["w-fit"]}  `}
              >
                {data?.title}
              </h1>
              <p style={{ lineHeight: "22px", textAlign: "justify" }}>
                {data?.summary}
              </p>
              <ul style={{ margin: 0 }} className={`${styles?.[""]} `}>
                {data?.features?.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default PerformanceSection;
